import React            from 'react';
import CycloTools       from './CycloTools';
import FractalViewer    from './FractalViewer';
import PersonalBlog     from './PersonalBlog';
import JavaScriptEditor from './JavaScriptEditor';

export default () => (
    <div className="dbSection">
        <div className="container">
            <h2>Projects</h2>
            <FractalViewer />
            <JavaScriptEditor />
            <CycloTools />
            <PersonalBlog />
        </div>
    </div>
);
