import React           from 'react';
import styles          from './Projects.module.scss';
import NewTabLink      from '../NewTabLink';
import codeEditorImage from './imgs/code-editor.png';

const JavaScriptEditor = () => (
    <div className={ styles.project }>
        <h3><NewTabLink to='https://code.david-brown.dev'>Browser Based JavaScript Editor/Interpreter</NewTabLink></h3>
        <p>
            My JavaScript code editor lets you edit and run modern JavaScript in the browser.
        </p>
        <div className={ styles.imageContainer }>
            <NewTabLink to={ 'https://code.david-brown.dev' }>
                <img
                    src={ codeEditorImage }
                    alt={ 'JavaScript Editor' }
                />
            </NewTabLink>
        </div>
        <p>
            It's using the babel <NewTabLink to={ 'https://babeljs.io/docs/en/babel-parser' }>parser</NewTabLink>/
            <NewTabLink to={ 'https://babeljs.io/docs/en/next/babel-traverse.html' }>traverser</NewTabLink>/
            <NewTabLink to={ 'https://babeljs.io/docs/en/babel-generator' }>generator</NewTabLink> to process your code
            in the browser before finally evaluating it in a cross-browser compatible way. Transformations are performed
            on the AST (
            <NewTabLink to={ 'https://en.wikipedia.org/wiki/Abstract_syntax_tree' }>abstract syntax tree</NewTabLink>)
            to redirect common statements such as the logging functions to internal state and then to components in the
            application, rather than the browser's standard log.
        </p>
        <p>
            I find interpreters really interesting, and this is a fun little project I'm working on at this stage.
        </p>
        <p>
            Some plans I have for the project are:
        </p>
        <ul>
            <li>
                Syntax Highlighting - The babel project is really amazing, and the parser used has a lot of stuff built
                in such as ranges in the code for code expressions. This points you to the exact locations in the
                original program string which contain certain expressions, and by extension lets you know exactly which
                bits of string are the offenders in the case of syntax errors. Although the parsing bit is done for me,
                I still expect this to be pretty challenging.
            </li>
            <li>
                Multiple Files - I plan to emulate/stub out the commonJS module system and simulate having multiple
                files in a "project", allowing imports, exports and inter-use of code.
            </li>
            <li>
                React with JSX! - This will include rendering to HTML and sandboxing it somewhat to be contained within
                an editor pane.
            </li>
        </ul>
    </div>
);

export default JavaScriptEditor;
