import styles     from './Projects.module.scss';
import NewTabLink from '../NewTabLink';
import React      from 'react';

export default () => (
    <div className={ styles.project }>
        <h3><NewTabLink to='https://david-brown.dev'>Personal Blog</NewTabLink></h3>
        <p>
            I created a small blog which I occasionally post to. Topics included will largely be web related,
            but will sometimes be things that I think are really cool and want to share with the world.
        </p>
    </div>
);
