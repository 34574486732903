import React  from 'react';
import styles from './Profile.module.scss';

export default () => (
    <>
        <div className="dbSection">
            <div className="container">
              <h2>About Me</h2>
              <p>
                  I'm the Technical Lead at IAG Connect.
              </p>
              <p>
                  I deliver the software that enables customers onboard various airlines within IAG (International
                Airlines Group) to connect to the onboard Wi-Fi.
              </p>
              <p>
                I am also responsible for delivery of the seatback interactive software, which is what you use to
                watch movies onboard long haul flights!
              </p>
              <p>
                The airlines we cater for are British Airways, Iberia, Vueling and Level.
              </p>
            </div>
        </div>
        <div className="dbSection">
            <div className="container">
                <h2>My Experience</h2>
              <div className={ styles.job }>
                <h3>
                  Technical Lead - IAG Connect | IAG Loyalty
                </h3>
                <div className="font-italic">
                  November 2022 - Present
                </div>
                <p>
                  I'm responsible for the software that connects you to the internet onboard British Airways, Iberia,
                  Vueling and Level, and for the software that provides you with media to watch onboard these airlines.
                </p>
                <p>
                  We smooth over the differences between multiple inflight connectivity providers, and provide the
                  same experience for customers of airlines across IAG (International Airlines Group).
                </p>
                <p>
                  Think of our inflight
                  connectivity providers as AWS in the air, providing us with the hardware, network, and the
                  capability to connect passengers to the internet over their network.
                </p>
              </div>
                <div className={ styles.job }>
                  <h3>
                    Senior Developer - Threads Styling
                  </h3>
                  <div className="font-italic">
                    January 2022 - November 2022
                  </div>s
                  <p>
                    I'm working on core enabling products that allow personal shoppers to manage stock, and provide
                    tailored suggestions to high profile clients.
                  </p>
                </div>
                <div className={ styles.job }>
                    <h3>
                        Senior Developer - Whitbread
                    </h3>
                    <div className="font-italic">
                        June 2021 - January 2022
                    </div>
                    <p>
                        I'm working on core products using react, migrating to frontends utilising a set of new
                        microservices, and away from AngularJS.
                    </p>
                </div>
                <div className={ styles.job }>
                    <h3>
                        Senior Developer - Mendeley, Elsevier
                    </h3>
                    <div className="font-italic">
                        November 2019 - June 2021
                    </div>
                    <p>
                        I'm working on a core product using React.js with redux. It's simultaneously a web and desktop
                        application through the magic of electron, and has a wide range of use cases including being
                        offline-first. It's a pretty heavily data-driven application, handling management of academic
                        references.
                    </p>
                </div>
                <div className={ styles.job }>
                    <h3>
                        Senior Developer - Lumina Learning
                    </h3>
                    <div className="font-italic">
                        January 2019 - November 2019
                    </div>
                    <p>
                        I'm responsible for the JavaScript based applications using Node.js, React.js and AngularJS,
                        ensuring code quality and assisting with technical direction within the team as a whole. There's
                        some exciting stuff I'll be working on during 2019 for Lumina, which will really revolutionise
                        the Psychometric world.
                    </p>
                </div>
                <div className={ styles.job }>
                    <h3>
                        Software Developer - Lumina Learning
                    </h3>
                    <div className="font-italic">
                        Autumn 2017 - Christmas 2018
                    </div>
                    <p>
                        Worked on some exciting new projects, including creation of an interactive Team-Viewer, allowing
                        comparisons of psychometric profiles within professional teams. I vastly improved and added a
                        lot of features to an Applicant Tracking System. Technically speaking, I've worked on the older
                        PHP systems, created inter-server APIs, created interactive front-ends and built new APIs,
                        maintained new and old systems, as well as mentoring interns during Summer 2018.
                    </p>
                </div>
                <div className={ styles.job }>
                    <h3>
                        Intern - Lumina Learning
                    </h3>
                    <div className="font-italic">
                        Summer 2016
                    </div>
                    <p>
                        Mainly involved working on the older systems at Lumina using PHP. I added a lot of new features
                        which was really appreciated by a lot of Lumina's customers. Notably migrated the entire system
                        to use a uniform interface for emailing customers, allowing for sending-failures and future
                        scheduling.
                    </p>
                </div>
                <div className={ styles.job }>
                    <h3>
                        Computer Science BSc - University of Southampton
                    </h3>
                    <div className="font-italic">
                        2014 - 2017
                    </div>
                    <p>
                        I graduated in 2017 with a First Class Computer Science degree. Southampton was a fantastic
                        environment for learning academic principles as you'd expect from a top University. Something I
                        loved about Southampton was the additional focus on practical application for a lot of concepts
                        that were taught.
                    </p>
                </div>
            </div>
        </div>
    </>
);
