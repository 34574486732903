import React        from 'react';
import styles       from './Projects.module.scss';
import NewTabLink   from '../NewTabLink';
import fractalImage from './imgs/fractal-viewer.PNG';

export default () => (
    <div className={ styles.project }>
        <h3><NewTabLink to="https://fractal.david-brown.dev">Fractal Viewer</NewTabLink></h3>
        <div className={ styles.imageContainer }>
            <NewTabLink

                to='https://fractal.david-brown.dev'
            >
                <img
                    src={ fractalImage }
                    alt="Mandelbrot Fractal"
                />
            </NewTabLink>
        </div>
        <p>
            If you're an ex Computer Scientist from Southampton University, you'll be well acquainted with
            Fractal renderers. One of the programming courseworks required creating a Fractal Viewer written in
            Java.
        </p>
        <p>
            I created a WebGL accelerated Fractal Renderer using vanilla WebGL. This is more of a learning
            exercise for me than a really useful tool, but I had a lot of fun implementing real-time renderering
            of fractals.
        </p>
        <p>
            I have a few more plans for this project, including
        </p>
        <ul>
            <li>Simulating double precision in glsl. WebGL is limited to regular 32 bit floats, meaning you
                cannot zoom very far into a fractal image, which is a shame since fractals are infinitely
                self-similar.
            </li>
        </ul>
    </div>
);
