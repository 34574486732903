import React      from 'react';
import styles     from './Headline.module.scss';
import image      from './me-image.jpg';
import NewTabLink from '../NewTabLink';

export default () => (
    <div className="dbSection">
        <div className="container">
            <h1 className='display-1'>David Brown</h1>
            <div id={ styles.pageSummary }>
                <div className={ styles.imageContainer }>
                    <img
                        src={ image }
                        alt={ 'David Brown Profile' }
                    />
                </div>
                <div>
                    <h3>Technical Lead</h3>
                    <p>
                        My personal website summarising my experience, links to my blog/contact, and some personal
                        projects.
                    </p>
                    <div>
                        <NewTabLink
                            to='https://david-brown.dev'
                            classes='btn btn-outline-primary'
                        >
                            Head over to my blog
                        </NewTabLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
