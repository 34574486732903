import React from 'react';

export default ({ to, children, classes = {} }) => (
    <a
        href={ to }
        target='_blank'
        rel="noopener noreferrer"
        className={ classes }
    >
        { children }
    </a>
);
