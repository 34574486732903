import React        from 'react';
import styles       from './Contact.module.scss';
import linkedInLogo from './imgs/2-Color/In-2C-128px-TM.png';
import emailLogo    from './imgs/email-icon-black-png-8.jpg.png';
import NewTabLink   from '../NewTabLink';

export default () => (
    <div className="dbSection" id={ styles.container }>
        <div className="container">
            <h2>
                Contact Me
            </h2>
            <p>
                Talk to me, the easiest way is through LinkedIn or emailing me.
            </p>
            <div className={ styles.contactContainer }>
                <NewTabLink to="https://www.linkedin.com/in/dcrbrown/">
                    <img src={ linkedInLogo } alt="LinkedIn" />
                </NewTabLink>
                <a href="mailto:dcrbrown@outlook.com?subject=I saw your website">
                    <img src={ emailLogo } style={ { width: '128px', height: '128px' } } alt="Email" />
                </a>
            </div>
        </div>
    </div>
);
