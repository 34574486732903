import React    from 'react';
import Headline from './Headline';
import Profile  from './Profile';
import Projects from './Projects';
import Contact  from './Contact';

export default () => (
    <div>
        <Headline />
        <Profile />
        <Projects />
        <Contact />
    </div>
);
