import React           from 'react';
import styles          from './Projects.module.scss';
import cycloToolsImage from './imgs/cyclo-tools.PNG';
import NewTabLink      from '../NewTabLink';

export default () => (
    <div className={ styles.project }>
        <h3>Cyclo-tools</h3>
        <div className={ styles.imageContainer }>
            <img
                src={ cycloToolsImage }
                alt="Cyclo Tools"
            />
        </div>
        <p>
            Cyclo-tools is a tool I built to help cyclists analyse activities.
        </p>
        <p>
            It identifies activities which are ridden on the same routes, and aggregates statistics for them.
            Plans
            for this tool in future include:
        </p>
        <ul>
            <li>Auto-tagging your activities as a commute - I know I always forget to!</li>
            <li>Highlight activities uploaded to <NewTabLink to="https://www.strava.com">Strava</NewTabLink> as
                duplicate. If
                you
                use Garmin and integrate your account with Strava, but upload directly to Strava, sometimes you
                might accidentally upload activities more than once. This is for my personal use, but have
                noticed
                several other people complaining about this in forums across the web
            </li>
        </ul>
    </div>
);
